<template>
    <div class="dataTables_paginate paging_simple_numbers">
        <ul class="pagination">
            <li class="paginate_button page-item prev" v-bind:class="{'disabled':(curpage<=chunk) }">
                <a
                    href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0"
                    class="page-link" v-on:click.stop.prevent="onPageJump('prev')">
                    Previous
                </a>
            </li>
            <template v-for="(page,index) in pages">
                <template v-if=" (Math.floor((curpage-1)/chunk)*chunk)+1 <= page ">
                    <template v-if=" ((Math.floor((curpage-1)/chunk)*chunk)+1)+chunk > page ">
                        <li class="paginate_button page-item" v-bind:class="{'active':(curpage==page)}" :key="index">
                            <a href="#" aria-controls="DataTables_Table_0"
                                :data-dt-idx="page" tabindex="0" class="page-link" 
                                v-on:click.stop.prevent="onPageNo(page)" v-text="page">
                            </a>
                        </li>
                    </template>
                </template>
            </template>
            <li class="paginate_button page-item next" v-bind:class="{'disabled':( Math.ceil(curpage/chunk) >= Math.ceil(pages/chunk) )}">
                <a href="#"
                    aria-controls="DataTables_Table_0" data-dt-idx="4" tabindex="0"
                    class="page-link" v-on:click.stop.prevent="onPageJump('next')">
                    Next
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props : [
        "pages", "curpage"
    ],
    mounted : function(){
    },
    data: function () {
        return {
            chunk : 5
        }
    },
    methods : {
        onPageNo : function(pg_no){
            this.curpage = pg_no;
            this.$emit('changePageNo', this.curpage);
        },
        onPageJump : function(tag){
            switch(tag){
                case 'prev' : {
                    var nextPage = this.curpage - this.chunk;
                    nextPage = nextPage - ((nextPage-1) % this.chunk);
                    this.curpage = Math.max(1, nextPage);
                    this.$emit('changePageNo', this.curpage);
                }break;
                case 'next' : {
                    var nextPage = this.curpage + this.chunk;
                    nextPage = nextPage - ((nextPage-1) % this.chunk);
                    this.curpage = Math.min(this.pages, nextPage);
                    this.$emit('changePageNo', this.curpage);
                }break;
            }
        }
    }
}
</script>
<style scoped>

</style>